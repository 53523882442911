import React from "react";

const Hero = () => {
  return (
    <div className="pt-4 pt-md-6 pt-lg-10">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-11 mb-lg-10"
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-11 mb-5">Shoppable Interactive Fashion Videos</h1>
              <h2 className="font-size-7 mb-0">
              Turn any runway video into an online store. Add overlays to products in your video and allow viewers to watch, click, and buy products that catch their eye. Showcase your products with relevant information such as price, size, or type.
              </h2>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
